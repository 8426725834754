import React from "react";
import PropTypes from "prop-types";
import { Box, Container, Drawer, IconButton } from "@mui/material";
import styles from "../Header/styles.module.scss";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import Logo from "../../assets/img/logo_.svg";
import Twitter from "../../assets/img/twitter.svg";
import Discord from "../../assets/img/reddit.svg";
import Instagram from "../../assets/img/insta.svg";
import OpenSea from "../../assets/img/social_b1.svg";

const Header = (props) => {
    const { window } = props;

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const drawer = (
        <>
            <Box className={styles.mobileNav}>
                <Box className={styles.header} variant="h6" component="div" sx={{ display: { xs: "block", md: "none" }, pt: { xs: 10, sm: 9 } }}>
                    <Box className={styles.logo}>
                        <a href="https://www.pixelwooks.com" target="_blank">
                            <img loading="lazy" src={Logo} alt="logo" />
                        </a>
                    </Box>
                </Box>
                <Box>
                    <CloseIcon onClick={handleDrawerToggle} className={styles.closeIcon} />
                </Box>
                <Box className={styles.social}>
                    <Box className={styles.socialIcons}>
                        <Box sx={{ pr: 1.8 }}>
                            <a href="https://opensea.io/collection/pixelwooks" target="_blank">
                                <img src={OpenSea} alt="open sea logo" />
                            </a>
                        </Box>
                        <Box sx={{ pr: 1.8 }}>
                            <a href="https://discord.com/invite/JTdvNkXRAZ" target="_blank">
                                <img src={Discord} alt="discord logo" />
                            </a>
                        </Box>
                        <Box sx={{ pr: 1.8 }}>
                            <a href="https://twitter.com/pixelw00ks" target="_blank">
                                <img src={Twitter} alt="twitter logo" />
                            </a>
                        </Box>
                        <Box>
                            <a href="https://www.instagram.com/pixelwooks/" target="_blank">
                                <img src={Instagram} alt="instagram logo" />
                            </a>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <>
            <Container>
                <Box className={styles.header}>
                    <Box className={styles.logo}>
                        <a href="https://www.pixelwooks.com" target="_blank">
                            <img src={Logo} alt="logo" />
                        </a>
                    </Box>
                    <Box className={styles.socialIcons} sx={{ display: { xs: "none", md: "flex" }, mt: 1.3 }}>
                        <Box sx={{ pr: 1.8 }}>
                            <a href="https://opensea.io/collection/pixelwooks" target="_blank">
                                <img src={OpenSea} alt="open sea logo" />
                            </a>
                        </Box>
                        <Box sx={{ pr: 1.8 }}>
                            <a href="https://discord.com/invite/JTdvNkXRAZ" target="_blank">
                                <img src={Discord} alt="discord logo" />
                            </a>
                        </Box>
                        <Box sx={{ pr: 1.8 }}>
                            <a href="https://twitter.com/pixelw00ks" target="_blank">
                                <img src={Twitter} alt="twitter logo" />
                            </a>
                        </Box>
                        <Box>
                            <a href="https://www.instagram.com/pixelwooks/" target="_blank">
                                <img src={Instagram} alt="instagram logo" />
                            </a>
                        </Box>
                    </Box>
                    <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ display: { md: "none" }, marginTop: "3px" }}>
                        <MenuIcon />
                    </IconButton>
                </Box>
            </Container>
            <Box component="nav" sx={{ backgroundColor: "transparent" }}>
                <Drawer
                    anchor="top"
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", md: "none" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box" },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box></>
    );
};
Header.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Header;
