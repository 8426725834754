
import TestPage from "./Pages/TestPage";
function App() {
    return (
        <>
            <TestPage />
        </>
    );
}

export default App;
