import Nft1 from "../../assets/gif/1.gif"
import Nft2 from "../../assets/gif/2.gif"
import Nft3 from "../../assets/gif/3.gif"
import Nft4 from "../../assets/gif/4.gif"
import Nft5 from "../../assets/gif/5.gif"
import Nft6 from "../../assets/gif/6.gif"
import Nft7 from "../../assets/gif/7.gif"
import Nft8 from "../../assets/gif/8.gif"
import Nft9 from "../../assets/gif/9.gif"
import Nft10 from "../../assets/gif/10.gif"
import Nft11 from "../../assets/gif/11.gif"
import Nft12 from "../../assets/gif/12.gif"

const NftData1 = [
    {
        image: Nft1
    },
    {
        image: Nft2
    },
    {
        image: Nft3
    },
]
const NftData2 = [
    {
        image: Nft4
    },
    {
        image: Nft5
    },
    {
        image: Nft6
    },

]
const NftData3 = [
    {
        image: Nft7
    },
    {
        image: Nft8
    },
    {
        image: Nft9
    },
]
const NftData4 = [
    {
        image: Nft10
    },
    {
        image: Nft11
    },
    {
        image: Nft12
    },
]

export { NftData1, NftData2, NftData3, NftData4 }