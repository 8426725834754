import React from 'react'
import Header from '../Components/Header'
import Home from '../Components/Home'
import Footer from '../Components/Footer'
const TestPage = () => {
    return (
        <>
            <Header />
            <Home />
            <Footer />
        </>
    )
}

export default TestPage
