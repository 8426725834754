// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_footer__cC-o9 {\n  padding: 15px 0;\n  background-color: black;\n}\n.styles_footer__cC-o9 p {\n  color: #fff;\n  margin: 8px 0 0 15px;\n}", "",{"version":3,"sources":["webpack://./src/Components/Footer/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,uBAAA;AACJ;AACI;EACI,WAAA;EACA,oBAAA;AACR","sourcesContent":[".footer {\n    padding: 15px 0;\n    background-color: black;\n\n    p {\n        color: #fff;\n        margin: 8px 0 0 15px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "styles_footer__cC-o9"
};
export default ___CSS_LOADER_EXPORT___;
