import React from "react";
import { Box, Container } from "@mui/material";
import styles from "./styles.module.scss";
import FooterLogo from "../../assets/img/footer_logo.svg";
import OpenSeaWhite from "../../assets/f_s_1.svg";
import DiscordWhite from "../../assets/f_s_2.svg";
import TwitterWhite from "../../assets/f_s_3.svg";
import InstWhite from "../../assets/f_s_4.svg";

const Footer = () => {
    return (
        <Box className={styles.footer}>
            <Container>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: { xs: "center", sm: "space-between" }, alignItems: "center" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <a href="https://www.pixelwooks.com" target="_blank">
                            <img src={FooterLogo} alt="" />
                        </a>
                        <p>© 2023 Pixelwooks</p>
                    </Box>
                    <Box sx={{ display: "flex", pt: { xs: 4, sm: 0 } }}>
                        <Box sx={{ pr: 1.8 }}>
                            <a href="https://opensea.io/collection/pixelwooks" target="_blank">
                                <img src={OpenSeaWhite} alt="open-sea white logo" />
                            </a>
                        </Box>
                        <Box sx={{ pr: 1.8 }}>
                            <a href="https://discord.com/invite/JTdvNkXRAZ" target="_blank">
                                <img src={DiscordWhite} alt="discord white logo" />
                            </a>
                        </Box>
                        <Box sx={{ pr: 1.8 }}>
                            <a href="https://twitter.com/pixelw00ks" target="_blank">
                                <img src={TwitterWhite} alt="twitter white logo" />
                            </a>
                        </Box>
                        <Box sx={{ pr: 1.8 }}>
                            <a href="https://www.instagram.com/pixelwooks/" target="_blank">
                                <img src={InstWhite} alt="instagram white logo" />
                            </a>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
